import Alpine from 'alpinejs';

(window as typeof window & { Alpine: typeof Alpine }).Alpine = Alpine;

document.addEventListener('alpine:init', () => {

  Alpine.data('menu', function() {
    return {
      open: false,
      toggle() {
        this.open = !this.open
      }
    }
  })

});

Alpine.start();


